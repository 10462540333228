import React, {FunctionComponent} from 'react';
import {useSubscription} from '@apollo/client';
import {TillDate, SSLTitle} from '../../helpers';
import {ResultCard} from '../result-card';
import {ToolResultProps, ResultCardItemSchema} from '../../types';
import {SSL_EXTRACTOR_SUBSCRIPTION, URL_CHECKER_SUBSCRIPTION} from '../../graphql/subscriptions';
import {isValidSSL} from '../../helpers';
import {Translate} from '../translations';
import {ProgressBar} from '../progressBar';

const SSLExtractorTool: FunctionComponent<ToolResultProps> = ({
  url,
  sessionToken,
  simpleResult,
  moreButton,
  handleScanLimitCheck,
}) => {
  const {data} = useSubscription(SSL_EXTRACTOR_SUBSCRIPTION, {
    variables: {url, sessionToken},
  });

  const {data: urlCheckerToolData} = useSubscription(URL_CHECKER_SUBSCRIPTION, {
    variables: {url, sessionToken},
  });

  const response = (data && data.sslExtractionTool) || {};
  const urlCheckerResponse = (urlCheckerToolData && urlCheckerToolData.urlCheckerTool) || {};

  const isValidSubjectName = response.sanList && isValidSSL(response.sanList, response.url);

  let cardItems: ResultCardItemSchema[] = [];
  if ('isHttps' in urlCheckerResponse) {
    cardItems.push({
      icon: urlCheckerResponse.isHttps ? 'check' : 'times',
      title: 'URL_CHECKER_HTTPS_RESULT_TITLE',
      description: urlCheckerResponse.isHttps ? (
        <>
          <Translate name="URL_CHECKER_HTTPS_RESULT_DESCRIPTION_SUCCESS" />
        </>
      ) : (
        'URL_CHECKER_HTTPS_RESULT_DESCRIPTION_FAIL'
      ),
      success: urlCheckerResponse.isHttps,
    });
  }
  if (simpleResult) {
    cardItems = [
      ...cardItems,
      {
        icon: response.validTo && SSLTitle(response.validTo) === 'SSL certificate expires in:' ? 'check' : 'times',
        title: response.validTo ? SSLTitle(response.validTo) : 'SSL_ECTRACTOR_TOOL_EXPIRES_AFTER',
        description: response.validTo ? TillDate(response.validTo) : 'No Data Available',
        success: !!(response.validTo && SSLTitle(response.validTo) === 'SSL certificate expires in:'),
      },
      {
        icon: response.subjectName ? 'check' : 'times',
        title: 'SSL_ECTRACTOR_TOOL_ISSUED_FOR',
        description: response.subjectName ? response.subjectName.trim() : 'No Data Available',
        success: !!response.subjectName,
      },
      {
        icon: response.issuer ? 'check' : 'times',
        title: 'SSL_ECTRACTOR_TOOL_ISSUER',
        description: response.issuer ? response.issuer.trim() : 'No Data Available',
        success: !!response.issuer,
      },
    ];
  } else {
    cardItems = [
      ...cardItems,
      {
        icon: response.validTo && SSLTitle(response.validTo) === 'SSL certificate expires in:' ? 'check' : 'times',
        title: response.validTo ? SSLTitle(response.validTo) : 'SSL_ECTRACTOR_TOOL_EXPIRES_AFTER',
        description: response.validTo ? TillDate(response.validTo) : 'No Data Available',
        success: !!(response.validTo && SSLTitle(response.validTo) === 'SSL certificate expires in:'),
      },
      {
        icon: response.subjectName ? 'check' : 'times',
        title: 'SSL_ECTRACTOR_TOOL_ISSUED_FOR',
        description: response.subjectName ? response.subjectName.trim() : 'No Data Available',
        success: !!response.subjectName,
      },
      {
        icon: response.issuer ? 'check' : 'times',
        title: 'SSL_ECTRACTOR_TOOL_ISSUER',
        description: response.issuer ? response.issuer.trim() : 'No Data Available',
        success: !!response.issuer,
      },
      {
        icon: isValidSubjectName ? 'check' : 'times',
        title: 'SSL_ECTRACTOR_TOOL_SUBJECT_DOMAINS',
        description: response.sanList ? (
          isValidSubjectName ? (
            response.sanList.join(', ')
          ) : (
            <div>
              {response.sanList.join(', ')} <br />
              <Translate name="SSL_INVALID_HOSTNAME" />
            </div>
          )
        ) : (
          'No Data Available'
        ),
        success: isValidSubjectName,
      },
      {
        icon: response.protocol ? 'check' : 'times',
        title: 'SSL_ECTRACTOR_TOOL_PROTOCOL',
        description: response.protocol ? response.protocol : 'No Data Available',
        success: !!response.protocol,
      },
      {
        icon: response.cipher ? 'check' : 'times',
        title: 'SSL_ECTRACTOR_TOOL_CIPHER',
        description: response.cipher ? response.cipher : 'No Data Available',
        success: response.cipher,
      },
    ];
  }

  if (response._error && response._error.code === 'UNAUTH_USER_SCANS_LIMIT_REACHED') {
    handleScanLimitCheck && handleScanLimitCheck(true);
  }

  return (!response.url || !urlCheckerResponse.url) && !response._error ? (
    <div className="text-center">
      <ProgressBar />
    </div>
  ) : !response._error ? (
    <div className="row">
      <div className="col-md-12">
        <ResultCard
          title={
            <>
              {moreButton}
              <Translate name="SSL_CHECKER_RESULT_TITLE" />
            </>
          }
          items={cardItems}
        />
      </div>
    </div>
  ) : null;
};

export default SSLExtractorTool;
