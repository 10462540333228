import React, {FC, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {navigate} from '@reach/router';

import Section from '../../components/section';
import ToolTabs from '../../components/scan-tools/ToolTabs';
import Layout from '../../../src/components/layout/layout';
import {DomainForm} from '../../components/form/domain-form';
import {PageHero} from '../../components/page-hero';
import ScanCountChecker from '../../components/scan-tools/scan-counter';
import ToolToken from '../../components/scan-tools/ToolToken';
import Button from '../../components/button';
import SSLExtractorTool from '../../components/scan-tools/sslExtractor';

import sslCheckerImg from '../../img/home-tools/ssl-checker.png';
import hexomaticCTA from '../../img/hexomatic-banner/hexomatic-cta.jpg';

import {TrackToolScan} from '../../helpers/trackingEvents';
import {ScanResultsPageProps} from '../../types';

const SSLExtractor: FC<ScanResultsPageProps> = props => {
  const [scanLimitReached, setScanLimitReached] = useState(false);
  const url = props['*'];
  const hasDomain = url && url.length > 0;

  const isMobile = useMediaQuery({maxWidth: 767});

  return (
    <Layout>
      <PageHero
        title={'SSL certificate checker tool'}
        subtitle={
          'Check the validity and configuration settings of the SSL certificate of any domain with our free SSL certificate checker tool.'
        }
      >
        <DomainForm value={url} onSubmit={u => navigate(`/ssl-certificate-checker/${encodeURIComponent(u)}`)} />
      </PageHero>
      <Section>
        {isMobile ? (
          <>
            <div className="col-md-9 tool-detail">
              <div className="row">
                <div className="col-md-12">
                  {hasDomain && url ? (
                    <ScanCountChecker url={url} scanLimitError={scanLimitReached}>
                      <ToolToken url={url}>
                        {sessionToken => {
                          TrackToolScan('SSLChecker', url);
                          return (
                            <div className="row">
                              <div className="col-md-12">
                                <SSLExtractorTool
                                  url={url}
                                  sessionToken={sessionToken}
                                  handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                />
                              </div>
                            </div>
                          );
                        }}
                      </ToolToken>
                    </ScanCountChecker>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-4" style={{marginBottom: '20px'}}>
                          <img src={sslCheckerImg} alt="" style={{width: '100%', maxWidth: '300px'}} />
                        </div>
                        <div className="col-md-8">
                          <p>
                            <strong>What is an SSL certificate?</strong>
                          </p>
                          <p>
                            SSL Certificates are the backbone of secure Internet access and they protect information as
                            it travels across the web by encrypting it. In addition to encryption, a valid SSL
                            certificate also provides authentication to ensure that you are sending information to the
                            right destination.
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p>
                            Maintaining a valid SSL Certificate is crucial to running a successful website, without a
                            valid certificate most web browsers will display a warning and search engines could
                            blacklist your domain.
                          </p>
                          <p>
                            <strong>There are 3 types of SSL certificates:</strong>
                          </p>
                          <ol>
                            <li>
                              Single-domain SSL — this certificate has only one SAN (Subject Alternative Name) field and
                              it contains a reference to a single website.
                            </li>
                            <li>
                              Wildcard (
                              <em>) SSL — when that one SAN field contains an asterisk in the website name (e.g. ‘</em>
                              .hexometer.com’) then it’s a wildcard certificate. This is a variety of single-domain SSL
                              but for an unlimited number of subdomains (a domain that is a part of another domain).
                            </li>
                            <li>
                              Multi-domain SSL — when the certificate has many SAN fields. Multi-domain certificates
                              sometimes have more than 100 SAN fields, and some of these fields may contain wildcards,
                              creating a hybrid “multi-domain wildcard” certificate. This type of certificate is often
                              provided free of charge by CDN (Content Delivery Networks) services. The main advantage of
                              multi-domain SSL — they are mostly free of charge.
                            </li>
                          </ol>
                          <p>
                            <em>
                              From 2014, Google made changes to its algorithm in order to give the upper hand to
                              HTTPS-enabled websites. From 2018 Google has decided to flag the websites which do not
                              have an SSL/TLS.
                            </em>
                          </p>
                          <p>
                            This SSL Checker tool will help you diagnose whether the website has an SSL Certificate or
                            not, and will retrieve any public data connected with it.
                          </p>
                          <p>
                            You can verify the SSL certificate, to make sure it is correctly installed, valid, trusted
                            and doesn't result in any errors.
                          </p>
                          <p>
                            <strong>The results of our free SSL Certificate checker tool includes:</strong>
                          </p>
                          <ul>
                            <li>SSL issued to</li>
                            <li>SSL issued by</li>
                            <li>The Hostname(s) listed on the certificate</li>
                            <li>SSL Certificate expires in</li>
                            <li>SSL Protocol</li>
                            <li>SSL Cipher</li>
                          </ul>
                        </div>
                      </div>
                      <div className="row" style={{marginTop: '30px'}}>
                        <div className="col-md-6" style={{margin: 'auto'}}>
                          <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                        </div>
                        <div className="col-md-6">
                          <div className="flex-align-center">
                            <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                            <p>
                              Harness the internet as your own data source, build your own scraping bots and leverage
                              ready made automations to delegate time consuming tasks and scale your business.
                            </p>
                            <p>No coding or PhD in programming required.</p>
                            <div>
                              <Button
                                hexomaticCTA
                                className="monitor_btn"
                                onClick={() =>
                                  (window.location.href =
                                    'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                                }
                              >
                                Get started in minutes
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <ToolTabs active="ssl-checker" url={url} />
            </div>
          </>
        ) : (
          <>
            <div className="col-md-3">
              <ToolTabs active="ssl-checker" url={url} />
            </div>
            <div className="col-md-9 tool-detail">
              <div className="row">
                <div className="col-md-12">
                  {hasDomain && url ? (
                    <ScanCountChecker url={url} scanLimitError={scanLimitReached}>
                      <ToolToken url={url}>
                        {sessionToken => {
                          TrackToolScan('SSLChecker', url);
                          return (
                            <div className="row">
                              <div className="col-md-12">
                                <SSLExtractorTool
                                  url={url}
                                  sessionToken={sessionToken}
                                  handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                />
                              </div>
                            </div>
                          );
                        }}
                      </ToolToken>
                    </ScanCountChecker>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-4" style={{marginBottom: '20px'}}>
                          <img src={sslCheckerImg} alt="" style={{width: '100%', maxWidth: '300px'}} />
                        </div>
                        <div className="col-md-8">
                          <p>
                            <strong>What is an SSL certificate?</strong>
                          </p>
                          <p>
                            SSL Certificates are the backbone of secure Internet access and they protect information as
                            it travels across the web by encrypting it. In addition to encryption, a valid SSL
                            certificate also provides authentication to ensure that you are sending information to the
                            right destination.
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p>
                            Maintaining a valid SSL Certificate is crucial to running a successful website, without a
                            valid certificate most web browsers will display a warning and search engines could
                            blacklist your domain.
                          </p>
                          <p>
                            <strong>There are 3 types of SSL certificates:</strong>
                          </p>
                          <ol>
                            <li>
                              Single-domain SSL — this certificate has only one SAN (Subject Alternative Name) field and
                              it contains a reference to a single website.
                            </li>
                            <li>
                              Wildcard (
                              <em>) SSL — when that one SAN field contains an asterisk in the website name (e.g. ‘</em>
                              .hexometer.com’) then it’s a wildcard certificate. This is a variety of single-domain SSL
                              but for an unlimited number of subdomains (a domain that is a part of another domain).
                            </li>
                            <li>
                              Multi-domain SSL — when the certificate has many SAN fields. Multi-domain certificates
                              sometimes have more than 100 SAN fields, and some of these fields may contain wildcards,
                              creating a hybrid “multi-domain wildcard” certificate. This type of certificate is often
                              provided free of charge by CDN (Content Delivery Networks) services. The main advantage of
                              multi-domain SSL — they are mostly free of charge.
                            </li>
                          </ol>
                          <p>
                            <em>
                              From 2014, Google made changes to its algorithm in order to give the upper hand to
                              HTTPS-enabled websites. From 2018 Google has decided to flag the websites which do not
                              have an SSL/TLS.
                            </em>
                          </p>
                          <p>
                            This SSL Checker tool will help you diagnose whether the website has an SSL Certificate or
                            not, and will retrieve any public data connected with it.
                          </p>
                          <p>
                            You can verify the SSL certificate, to make sure it is correctly installed, valid, trusted
                            and doesn't result in any errors.
                          </p>
                          <p>
                            <strong>The results of our free SSL Certificate checker tool includes:</strong>
                          </p>
                          <ul>
                            <li>SSL issued to</li>
                            <li>SSL issued by</li>
                            <li>The Hostname(s) listed on the certificate</li>
                            <li>SSL Certificate expires in</li>
                            <li>SSL Protocol</li>
                            <li>SSL Cipher</li>
                          </ul>
                        </div>
                      </div>
                      <div className="row" style={{marginTop: '30px'}}>
                        <div className="col-md-6" style={{margin: 'auto'}}>
                          <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                        </div>
                        <div className="col-md-6">
                          <div className="flex-align-center">
                            <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                            <p>
                              Harness the internet as your own data source, build your own scraping bots and leverage
                              ready made automations to delegate time consuming tasks and scale your business.
                            </p>
                            <p>No coding or PhD in programming required.</p>
                            <div>
                              <Button
                                hexomaticCTA
                                className="monitor_btn"
                                onClick={() =>
                                  (window.location.href =
                                    'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                                }
                              >
                                Get started in minutes
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Section>
    </Layout>
  );
};

export default SSLExtractor;
